<template>
  <div></div>
</template>
<script>
export default {
  mounted () {
    this.$store.dispatch('account/logout').then(() => {
      this.$router.push('/auth/login')
      // location.reload()
    }).catch(() => {
      this.$router.push('/auth/login')
      // location.reload()
    })
  }
}
</script>